<template>
  <v-container v-if="event && activityResult">
    <h1 class="title px-8 my-5">{{ $t('activities.resultsPage.title') }}</h1>
    <p class="body-1 text-center" v-if="!hasResults">
      {{ $t('activities.resultsPage.noResult') }}
    </p>
    <activity-list :user="loggedInUser" :activities="getActivities" :event="event" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { DateTime } from 'luxon';

import ActivityUtil from '@/helpers/activity/activity.helper';

import {
  ACTIVITY_MODULE,
  GET_ACTIVITY_RESULT,
} from '@/stores/umanize-app/actions/activity/activity.actions';

import ActivityList from '@/components/activity-list/ActivityList.vue';
import { APP_EVENT_MODULE } from '@/stores/umanize-app/actions/event/app-event.actions';
import { APP_USER_MODULE } from '@/stores/umanize-app/actions/user/app-user.actions';

export default {
  name: 'ActivitiesResults',
  components: {
    ActivityList,
  },
  data: () => ({
    timer: null,
    now: DateTime.local().toISO(),
    carouselIndex: 0,
  }),
  computed: {
    ...mapGetters(APP_EVENT_MODULE, ['event']),
    ...mapGetters(APP_USER_MODULE, ['loggedInUser']),
    ...mapGetters(ACTIVITY_MODULE, ['activityResult']),
    hasResults() {
      return this.activityResult?.discussionGroups;
    },
    getActivities() {
      const { discussionGroups, conferences } = this.activityResult;

      return ActivityUtil.buildActivities({ discussionGroups, conferences });
    },
  },
  methods: {
    ...mapActions(ACTIVITY_MODULE, [GET_ACTIVITY_RESULT]),
  },
  mounted() {
    this[GET_ACTIVITY_RESULT](this.$route.params.eventId);
  },
  created() {
    this.timer = window.setInterval(() => {
      this.now = DateTime.local().toISO();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/core/variables';

.results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;
}

.result {
  box-sizing: border-box;
  width: 100%;
}

@include breakpoint(small) {
  .result {
    width: 50%;
  }
}

@include breakpoint(medium) {
  .result {
    width: 33%;
  }
}
</style>
